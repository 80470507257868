<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Add Missed Punch" slot="title" link="/helpContent/Add Missed Punch" />
        <ValidationObserver ref="validator">
        <FormRow>
                <FormRow>
                <div class="col-sm-4">
                    <inputDate label="Date" v-model="form.date" rules="required" />
                </div>
                <div class="col-sm-2">
                    <InputTime label="Time" v-model="form.time" rules="required" />
                </div>
                </FormRow>
                <FormRow>
                <div class="col-sm-8">
                    <FormLeaveType v-model="form.in_out" rules="required">
                    </FormLeaveType>
                </div>
                </FormRow>
                <FormRow>
                <div class="col-sm-4">
                <InputTextArea label="Reason" rows="3" :maxlength="5000" v-model="form.Notes" rules="required" />
                 </div>
                 </FormRow>
                <div class="col-sm-4">
                    <ButtonGroup>
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
                </div>
        </FormRow>
        </ValidationObserver>
        <br />
        <h4>Leave Matrix</h4>
        <br/>
        <DataTable :actions="true" 
        :data="leaveMatrixData" 
        :columns="tableConfig" 
        :is-loading="isLoading"
        :pagination="pagination">
        </DataTable>
        <Popup title="Information" type="info" :value="infoMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ infoMessage.message }}
        </Popup>
        <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ showMessage.message }}
        </Popup>
    </FormWrapper>
    </template>
    <script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import DataTable from 'Components/DataTable'
    import loadingMixin from 'Mixins/loadingMixin'
    import InputDate from "Components/form/InputDate";
    import {leaveMatrix,insertMissedPunchDetails} from '../api'
    import tableConfig from './table'
    import FormLeaveType from "../FormLeaveType";
    import {ValidationObserver } from 'vee-validate'
    export default {
        name: "AddMissedPunch",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            DataTable,
            PageTitle,
            ValidationObserver,
            InputDate,
            FormLeaveType,
        },
        data() {
            return {
                showMessage: {
                    isVisible: false,
                },
                infoMessage: {
                    isVisible: false,
                    message: ''
                },
                form: {
                    date:'',
                    time:'',
                    in_out: "",
                    Notes:'',
                },
                staffList: [],
                sorting: {
                    key: 'NAME',
                    order: 'asc'
                },
                pagination: {
                    perPage: 10,
                    page: 1,
                    total: 0
                },
                tableConfig,
                leaveMatrixData: [],
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user
            }
        },
        watch: {},
        created() {
        this.getAllList()
        },
        methods: {
          sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                          staffid: this.userData.user_id,
                          date: this.form.date,
                          time: this.form.time,
                          in_out: this.form.in_out,
                          Notes: this.form.Notes,
                        }; 
                        insertMissedPunchDetails(data).then((res)=>{
                            if(res.data[0].status === 1){
                                this.form = {};
                                this.showMessage.isVisible=true;
                                this.showMessage.message=res.data[0].message;
                            } else{
                                this.infoMessage.isVisible=true;
                                this.infoMessage.message=res.data[0].message;
                            }
                            
                        })
                    }
                })
            },
            async getAllList() {
                let data = {
                staff_id: this.userData.user_id,
                };
            await leaveMatrix(data).then((res) => {
                this.leaveMatrixData = res.data;
                });
            },
        }
    }
    </script>
    <style lang="scss">
    .popupRow {
        padding: 5px 0 15px;
    }
    .pagination {
        margin: 10px 0 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        line-height: 26px;
    }
    .paginationText {
        margin: 0 20px;
    }
    .paginationButton {
        cursor: pointer;
        width: 26px;
        text-align: center;
        transition: background-color .2s ease-in-out, color .2s ease-in-out, box-shadow 200ms ease
    }
    .paginationButton:hover {
        background: #57a3f3;
        color: #fff;
        &:hover {
            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
        }
    }
    .filter {
        margin: -10px 0 10px;
    }
    .filterItem {
        display: inline-block;
        margin: 0 15px 15px 0;
    }
    .filterItemCheckbox {
        flex-basis: auto;
        align-self: flex-start;
        margin-top: 13px;
    }
    </style>
    